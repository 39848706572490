import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';
import Hints from './Hints';

const tooltipSx: SxProps = {
    bgcolor: 'grey.300',
    maxWidth: 'none',
    minWidth: '336px',
    p: 2,
};

const tooltipArrowSx: SxProps = {
    '&::before': {
        bgcolor: 'grey.300',
    },
};

interface InfoIconWithHintsProps {
    close?: () => void;
    hints?: string[];
    isOpen?: boolean;
    open?: () => void;
}

const InfoIconWithHints: FC<InfoIconWithHintsProps> = ({ close, hints = [], isOpen, open }) => {
    if (!hints.length) {
        return <InfoIcon sx={{ alignSelf: 'center', mx: 1 }} />;
    }

    return (
        <Tooltip
            componentsProps={{ arrow: { sx: tooltipArrowSx }, tooltip: { sx: tooltipSx } }}
            leaveDelay={100}
            onClose={close}
            onOpen={open}
            open={isOpen}
            placement="top-start"
            sx={{ maxHeight: '15px' }}
            title={<Hints hints={hints} />}
            arrow
            disableFocusListener
        >
            <InfoOutlinedIcon sx={{ alignSelf: 'center', color: 'grey.400', mr: 1 }} />
        </Tooltip>
    );
};

export default InfoIconWithHints;
