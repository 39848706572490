/** A marker class name used to store selected menu item directly in DOM */
export const SELECTED_MENU_ITEM_ID = 'selectedQuickSearchMenuItem';
/**
 * Add an identity class MENU_ITEM_ID to each displayed MenuItem to be able to
 * access to all items as by class name
 */
export const MENU_ITEM_ID = 'quickSearchMenuItem';

export enum HelperString {
    MACOS = '⌘K',
    WIN = 'Ctrl+K',
}
