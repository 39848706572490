import { Languages, LanguageUppercase, LawScope, MainKeywordEnum, ModelType } from '@livv/models';
import { getEnvVar } from '@config/configEnv';
import { getHierarchyLevels } from '@utils/search';
import { Attribute, Filter, FilterType } from '@utils/types/search';

// TODO delete INDEX_NAME ?
// If we can avoid using NEXT_PUBLIC_ env variables, it would be better
// for now, we can't do otherwise but to improve
// because we use the credentials in the functions (config) to get the indexes in the api key generation
// in the end we need INDEX_NAME to build the other indexes
export const INDEX_NAME = getEnvVar('ALGOLIA_INDEX_NAME');
export const INDEX_NAME_GLOSSARY = `${INDEX_NAME}_glossary`;
export const INDEX_NAME_PERFORMED_AT_DESC = `${INDEX_NAME}_sortByDate_desc`;
export const INDEX_NAME_PERFORMED_AT_ASC = `${INDEX_NAME}_sortByDate_asc`;
/* Number of recent and saved searches in UnderbarCard and localStorage */
export const MAX_SEARCHES = 10;
export const MIN_SEARCHES = 3;

export const BASE_GLOSSARY_FILTERS: Filter[] = [
    {
        attributes: getHierarchyLevels('keywordHierarchy', 14) as Attribute[],
        title: 'thematicPlan',
        type: FilterType.HIERARCHICAL_MENU,
    },
    {
        attributes: [Attribute.LANGUAGE],
        facetOverrides: Object.values(Languages),
        type: FilterType.REFINEMENT_LIST,
    },
    {
        attributes: [Attribute.LAW_SCOPES],
        facetOverrides: Object.values(LawScope),
        type: FilterType.REFINEMENT_LIST,
    },
];

export const BASE_FILTERS: Filter[] = [
    {
        attributes: [Attribute.MODEL_TYPE],
        facetOverrides: Object.values(ModelType),
        type: FilterType.REFINEMENT_LIST,
    },
    {
        attributes: [Attribute.LANGUAGE],
        facetOverrides: Object.values(Languages),
        type: FilterType.REFINEMENT_LIST,
    },
    {
        attributes: [Attribute.LAW_SCOPES],
        facetOverrides: Object.values(LawScope),
        type: FilterType.REFINEMENT_LIST,
    },
];

export const SPECIFIC_FILTERS: Filter[] = [
    {
        attributes: getHierarchyLevels('juridictionHierarchy', 3) as Attribute[],
        isSpecific: true,
        targetedModelTypes: [ModelType.DECISION],
        title: 'jurisdiction',
        type: FilterType.HIERARCHICAL_MENU,
    },
    {
        attributes: getHierarchyLevels('keywordHierarchy', 14) as Attribute[],
        isSpecific: true,
        targetedModelTypes: [ModelType.PUBLICATION_SHEET],
        title: 'book',
        type: FilterType.HIERARCHICAL_MENU,
    },
    {
        attributes: [Attribute.NATURE],
        ignoreTranslation: true,
        isSpecific: true,
        searchable: true,
        targetedModelTypes: [ModelType.LAW_TEXT],
        title: 'nature',
        type: FilterType.REFINEMENT_LIST,
    },
];

export const MAX_HITS_FOR_EXHAUSTIVE = 1000;

export const LANGUAGE_FACET = [LanguageUppercase.FR, LanguageUppercase.EN];

export const LAWSCOPE_FACET = [LawScope.FR, LawScope.EU];

export const MAINKEYWORD_FACET = Object.values(MainKeywordEnum);

export const MODELTYPE_FACET = [
    ModelType.DECISION,
    ModelType.LAW_TEXT,
    ModelType.PUBLICATION_SHEET,
    ModelType.GLOSSARY_TERM,
    ModelType.STATISTIC,
];
