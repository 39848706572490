import SearchIcon from '@mui/icons-material/Search';
import { MenuList, PaletteMode, Theme } from '@mui/material';
import { FC } from 'react';
import MenuItem from './MenuItem';
import SavedAndRecentSearches from './SavedAndRecentSearches';
import { QuickSearchItem } from '@utils/types/search';

interface SuggestionsProps {
    items?: QuickSearchItem[];
    searchQuery: string;
    colorVersion?: PaletteMode;
}

const suggestionSx = (colorVersion: PaletteMode) => ({
    '& li': {
        '&:hover, &:focus': {
            bgcolor: colorVersion === 'dark' ? 'common.black' : 'grey.700',
            outline: (theme: Theme) =>
                `1px solid ${theme.palette.grey[colorVersion === 'dark' ? 200 : 500]}`,
        },
        m: 0,
        mb: 1 / 8,
    },
    color: colorVersion === 'dark' ? 'common.white' : 'grey.100',
});

const Suggestions: FC<SuggestionsProps> = ({ items, searchQuery, colorVersion }) => (
    <MenuList data-test-id="quicksearch-list-results" sx={suggestionSx(colorVersion || 'light')}>
        <SavedAndRecentSearches colorVersion={colorVersion} searchQuery={searchQuery} />
        {items?.map((item, index) => (
            <MenuItem
                {...item}
                key={`menu-item-${item.title}-${+index}`}
                colorVersion={colorVersion}
                dataTestId="quicksearch-result-item"
            />
        ))}
        {searchQuery && (
            <MenuItem Icon={<SearchIcon />} colorVersion={colorVersion} title={searchQuery} />
        )}
    </MenuList>
);
export default Suggestions;
