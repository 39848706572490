import { UserSubCollectionName } from '@livv/models';
import { collection, doc, query, Query } from 'firebase/firestore';
import { RecentSearch } from '@components/search/algolia/RecentSearch/hooks/useRecentSearchMethods';
import { UserSavedSearchDataWithId } from '@models/users';
import { usersCollection } from '@utils/collections';
import { firestoreIdConverter } from '@utils/firestoreCollection';

export const escapeSpecialChar = (text: string): string =>
    text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

export const queryMatchesSearches = (current: string, compare: string): boolean => {
    if (!current) return true;

    const currEscapedChar = escapeSpecialChar(current);
    const reg = new RegExp(currEscapedChar, 'i');

    return reg.test(compare);
};

export const filterSearches = (
    { searchTerm }: RecentSearch | UserSavedSearchDataWithId,
    queriedValue: string,
): boolean => queryMatchesSearches(queriedValue, searchTerm);

export const getSavedSearchesQuery = (userId: string): Query<UserSavedSearchDataWithId> =>
    query(
        collection(
            doc(usersCollection, userId),
            UserSubCollectionName.SAVED_SEARCHES,
        ).withConverter<UserSavedSearchDataWithId>(firestoreIdConverter),
    );
