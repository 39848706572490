import * as yup from 'yup';
import { searchableRecords } from '@functions';

const SEARCH_KEY = 'SEARCH_PUBLIC_KEY';
const SEARCH_RESTRICTED_KEY = 'SEARCH_RESTRICTED_PUBLIC_KEY';
const ONE_HOUR_SECONDS = 3600;

interface SearchKey {
    // mimics output of searchableRecordsRequestKey
    analyticsTags: string[];
    appId: string;
    expires: number;
    indexName: string;
    searchKey: string;
}

const SearchKeySchema = yup.object().shape({
    analyticsTags: yup.array().of(yup.string()).required(),
    appId: yup.string().required(),
    expires: yup.number().required(),
    indexName: yup.string().required(),
    searchKey: yup.string().required(),
});

const getSearchApiKey = async (isAllowedToConsumePaidContent: boolean): Promise<SearchKey> => {
    // for cypress tests
    if (
        process &&
        process.env?.NEXT_PUBLIC_E2E_ALGOLIA_APP_ID &&
        process.env?.NEXT_PUBLIC_E2E_ALGOLIA_API_KEY &&
        process.env?.NEXT_PUBLIC_E2E_ALGOLIA_INDEX_NAME
    ) {
        return {
            analyticsTags: [],
            appId: process.env.NEXT_PUBLIC_E2E_ALGOLIA_APP_ID,
            expires: Math.floor(Date.now() / 1000) + ONE_HOUR_SECONDS,
            indexName: process.env.NEXT_PUBLIC_E2E_ALGOLIA_INDEX_NAME,
            searchKey: process.env.NEXT_PUBLIC_E2E_ALGOLIA_API_KEY,
        };
    }

    const storageKey = isAllowedToConsumePaidContent ? SEARCH_KEY : SEARCH_RESTRICTED_KEY;
    const storageData = localStorage.getItem(storageKey);
    const isValidStorageData = storageData && SearchKeySchema.isValidSync(JSON.parse(storageData));

    if (isValidStorageData) {
        const localKey = JSON.parse(storageData);

        if (localKey.expires > Math.floor(Date.now() / 1000) + ONE_HOUR_SECONDS) {
            return localKey;
        }
    }

    const newKey = (
        await searchableRecords.requestKey({
            isAllowedToConsumePaidContent,
            searchContext: 'PUBLIC',
        })
    ).data as SearchKey;

    localStorage.setItem(storageKey, JSON.stringify(newKey));

    return newKey;
};

export default getSearchApiKey;
