import { ModelType } from '@livv/models';
import { BASE_FILTERS, SPECIFIC_FILTERS } from '@utils/consts/search';
import { Filter, ModelAll } from '@utils/types/search';

export const getHierarchyLevels = (attribute: string, maxLvl: number): string[] =>
    [...Array(maxLvl)].map((_, index) => `${attribute}.lvl${index}`);

type SelectedModels = ModelType | ModelAll;

interface Filters {
    genericFilters: Filter[];
    specificFilters: Filter[];
}

export const getAppliedFilters = (selectedModelTypes: SelectedModels[]): Filters => {
    const selectedModels = selectedModelTypes.filter((model) => model !== ModelAll.All);

    const selectedFiltersFromModel = SPECIFIC_FILTERS.filter((specificFilter) => {
        if (selectedModels.length === 0) return false;

        return selectedModels.every(
            (type) => specificFilter.targetedModelTypes?.includes(type as ModelType),
        );
    });

    return { genericFilters: BASE_FILTERS, specificFilters: selectedFiltersFromModel };
};
