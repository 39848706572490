import { Button, Typography, PaletteMode } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import MenuItem from './MenuItem';
import { MIN_SEARCHES, MAX_SEARCHES } from '@utils/consts/search';
import { QuickSearchItem } from '@utils/types/search';

interface MenuItemsProps {
    Icon: React.ReactNode;
    colorVersion?: PaletteMode;
    items: QuickSearchItem[];
    title: string;
}

const seeMoresx = {
    button: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        ml: 2,
        pb: 2,
    },
    typography: {
        borderBottom: 1,
        borderColor: 'primary.main',
        color: 'primary.main',
        typography: 'body2',
    },
};

const MenuItems: FC<MenuItemsProps> = ({ Icon, items, title, colorVersion }) => {
    const [limitItems, setLimitItems] = useState(true);
    const { t } = useTranslation('common');

    return (
        <>
            <Typography
                component="li"
                sx={{ color: 'grey.400', pointerEvents: 'none', pt: 2 }}
                gutterBottom
            >
                {title}
            </Typography>
            {items.slice(0, limitItems ? MIN_SEARCHES : MAX_SEARCHES).map((item) => (
                <MenuItem key={item.title} colorVersion={colorVersion} {...item} Icon={Icon} />
            ))}
            {items?.length > MIN_SEARCHES && (
                <Button
                    data-test-id="see-more-button"
                    onClick={(event) => {
                        event.stopPropagation();
                        setLimitItems(!limitItems);
                    }}
                    sx={seeMoresx.button}
                    disableRipple
                >
                    <Typography component="span" sx={seeMoresx.typography}>
                        {t(`see${limitItems ? 'More' : 'Less'}`)}
                    </Typography>
                </Button>
            )}
        </>
    );
};

export default MenuItems;
