import { Dialog, DialogTitle, Button, DialogContent, DialogActions } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { cloneElement, FC, MouseEventHandler, ReactElement, ReactNode, useState } from 'react';

interface ConfirmationDialogProps {
    children: ReactNode;
    disabled?: boolean;
    onConfirm: () => void;
    text: string;
    title?: string;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    disabled = false,
    children,
    onConfirm,
    text,
    title,
}) => {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!disabled) {
            setOpen(true);
        }
    };
    const handleCancel: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen(false);
    };
    const handleConfirm: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen(false);
        onConfirm();
    };

    return (
        <>
            {children && cloneElement(children as ReactElement, { onClick: handleClick })}
            <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
                <DialogTitle sx={{ color: 'text.secondary' }}>
                    {title || t('modal.title')}
                </DialogTitle>
                <DialogContent>{text}</DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button onClick={handleCancel}>{t('modal.cancel')}</Button>
                    <Button data-test-id="confirm" onClick={handleConfirm} variant="contained">
                        {t('modal.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmationDialog;
