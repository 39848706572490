import { ModelType } from '@livv/models';
import { FC, useMemo } from 'react';
import { Configure as AlgoliaConfigure, ConfigureProps } from 'react-instantsearch';

const DEFAULT_FILTERS = `(NOT isPublished:false) AND (NOT modelType:${ModelType.ANALYSIS})`;

/**
 * Use this configure instead of Algolia's default when adding filters
 */
// TODO: remove this component when libraries update fixes the issue. Internally types have all necessary props
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Configure: FC<ConfigurePropsWithConfirmedProps> = ({ filters, ...configureProps }) => {
    const completeConfigureProps: ConfigureProps = useMemo(
        () => ({
            ...configureProps,
            clickAnalytics: true,
            filters: filters ? `${filters} AND ${DEFAULT_FILTERS}` : DEFAULT_FILTERS,
        }),
        [configureProps, filters],
    );

    return <AlgoliaConfigure {...completeConfigureProps} />;
};

export default Configure;
