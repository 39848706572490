import { Box, Typography, Stack } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

const hintTitleSx = {
    backgroundColor: 'grey.400',
    borderRadius: 1,
    color: 'grey.100',
    fontWeight: 'medium',
    mr: 1,
    px: 0.75,
    py: 0.25,
    textAlign: 'center',
};

interface HintsProps {
    hints: string[];
}

const Hints: FC<HintsProps> = ({ hints }) => {
    const { t } = useTranslation('common');

    return (
        <>
            <Typography sx={{ fontWeight: 'medium', textAlign: 'center' }}>
                {t('algoliaSearchBox.hints.title')}
            </Typography>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Box component="table" sx={{ mx: 0, my: 2 }}>
                    {hints.map((hint) => (
                        <tr key={hint}>
                            <td>
                                <Typography sx={hintTitleSx} variant="body2">
                                    {t(`algoliaSearchBox.hints.${hint}.title`)}
                                </Typography>
                            </td>
                            <td>
                                <Typography variant="caption">
                                    {t(`algoliaSearchBox.hints.${hint}.hint`)}
                                </Typography>
                            </td>
                        </tr>
                    ))}
                </Box>
            </Stack>
        </>
    );
};

export default Hints;
