import { Languages } from '@livv/models';
import { fromUnixTime } from 'date-fns';
import { IndexUiState } from 'instantsearch.js';
import { Translate } from 'next-translate';
import { getDate, isSameDay } from '@utils/date';
import { Attribute } from '@utils/types/search';

interface FormattedFilter {
    name: string;
    value: string | string[];
}

const translateSpecificFilter = (key: Attribute, value: string, t: Translate): string => {
    switch (key) {
        case Attribute.MODEL_TYPE:
            return t(`modelType:${value}`);
        case Attribute.NATURE:
            return value;
        case Attribute.LANGUAGE:
            return t(`${key}.${value}`);
        case Attribute.LAW_SCOPES:
            return t(`lawScope.${value}`);
        default:
            return t(`search:filters.filter.${key}.${value}`);
    }
};

interface MenuFilter {
    name: string;
    value: string;
}

const makeModelTypeFirst = (facetFilters: MenuFilter[]) => {
    const modelTypeFilter = facetFilters.find(({ name }) => name === Attribute.MODEL_TYPE);
    if (modelTypeFilter) {
        return [
            modelTypeFilter,
            ...facetFilters.filter(({ name }) => name !== Attribute.MODEL_TYPE),
        ];
    }

    return facetFilters;
};

const formatSavedSearchFilters = (
    query: IndexUiState,
    t: Translate,
    lang: string,
): FormattedFilter[] => {
    const { hierarchicalMenu, menu, range } = query;

    const menuFilters: MenuFilter[] = Object.entries(menu || {}).map(([key, value]) => ({
        name: key,
        value: translateSpecificFilter(key as Attribute, value, t),
    }));

    const hierarchicalFilters = Object.entries(hierarchicalMenu || {}).map(([key, value]) => ({
        name: key,
        value: Array.isArray(value) ? value.join(' / ') : value,
    }));

    const rangeDate = Object.entries((range as Record<string, string>) || {}).map(
        ([key, value]) => {
            const [min, max] = value.split(':').map(Number);

            return {
                name: key,
                value: isSameDay(min, max)
                    ? t('savedSearch.sortByDate', {
                          date: getDate(fromUnixTime(Number(min)), lang as Languages, true),
                      })
                    : t(`savedSearch.sortByRange`, {
                          from: getDate(fromUnixTime(min), lang as Languages, true),
                          to: getDate(fromUnixTime(max), lang as Languages, true),
                      }),
            };
        },
    );

    return [...makeModelTypeFirst(menuFilters), ...hierarchicalFilters, ...rangeDate];
};

export default formatSavedSearchFilters;

export const renderFiltersAsString = (query: IndexUiState, t: Translate, lang: string): string => {
    const filters = formatSavedSearchFilters(query, t, lang);
    const filtersToOmit = [Attribute.LAW_SCOPES];

    return filters
        .reduce((displayedFilters: string[], { name, value }) => {
            if (!filtersToOmit.includes(name as Attribute)) {
                const valueToAdd = Array.isArray(value) ? value.join(', ') : value;

                displayedFilters = [...displayedFilters, valueToAdd];
            }

            return displayedFilters;
        }, [])
        .join(' > ');
};
