import { UserSubCollectionName } from '@livv/models';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookmarkOutlineIcon from '@mui/icons-material/BookmarkBorder';
import CloseIcon from '@mui/icons-material/Close';
import MailFilledIcon from '@mui/icons-material/Mail';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton, Tooltip, PaletteMode } from '@mui/material';
import { collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import MenuItems from './MenuItems';
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog';
import useRecentSearchMethods from '@components/search/algolia/RecentSearch/hooks/useRecentSearchMethods';
import { UserSavedSearchDataWithId } from '@models/users';
import { usersCollection } from '@utils/collections';
import { useAuth } from '@utils/context/auth';
import { renderFiltersAsString } from '@utils/search/formatSavedSearchFilters';
import { filterSearches, getSavedSearchesQuery } from '@utils/search/savedAndRecentSearches';
import { QuickSearchItem } from '@utils/types/search';

interface SuggestedSearchesProps {
    colorVersion?: PaletteMode;
    searchQuery: string;
}

const SuggestedSearches: FC<SuggestedSearchesProps> = ({ searchQuery, colorVersion }) => {
    const { user } = useAuth();
    const { t, lang } = useTranslation('common');
    // Saved searches in firestore
    const [savedSearches] = useCollectionData<UserSavedSearchDataWithId>(
        user?.uid ? getSavedSearchesQuery(user.uid) : undefined,
    );
    // Recent searches in local storage
    const { getItems, deleteItem } = useRecentSearchMethods();
    const [recentSearches, setRecentSearches] = useState(getItems());

    const handleDeleteSavedSearch = async (savedSearchId: string): Promise<void> => {
        await deleteDoc(
            doc(
                collection(doc(usersCollection, user?.uid), UserSubCollectionName.SAVED_SEARCHES),
                savedSearchId,
            ),
        );
    };

    const handleToggleEmailPreferences = async (
        savedSearchId: string,
        value: boolean,
    ): Promise<void> => {
        await updateDoc(
            doc(
                collection(doc(usersCollection, user?.uid), UserSubCollectionName.SAVED_SEARCHES),
                savedSearchId,
            ),
            {
                isGettingNewAssociatedResultsByEmail: !value,
            },
        );
    };

    const handleDeleteRecentSearch = (queryToDelete: string) => {
        deleteItem(recentSearches, queryToDelete);
        setRecentSearches(getItems());
    };

    // Filter searches based on user searchQuery and format for display
    const filteredSavedSearches = savedSearches
        ?.filter((savedSearch) => filterSearches(savedSearch, searchQuery))
        .map(
            ({ id, isGettingNewAssociatedResultsByEmail, query: savedSearchQuery, searchTerm }) =>
                ({
                    Actions: (
                        <>
                            <Tooltip
                                title={t(
                                    `savedSearch.getNewResultsByEmail.hint.${
                                        isGettingNewAssociatedResultsByEmail ? 'disable' : 'enable'
                                    }`,
                                )}
                            >
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleToggleEmailPreferences(
                                            id,
                                            isGettingNewAssociatedResultsByEmail,
                                        );
                                    }}
                                    sx={{ color: 'inherit' }}
                                >
                                    {isGettingNewAssociatedResultsByEmail ? (
                                        <MailFilledIcon />
                                    ) : (
                                        <MailOutlineIcon />
                                    )}
                                </IconButton>
                            </Tooltip>
                            <ConfirmationDialog
                                onConfirm={async () => {
                                    handleDeleteSavedSearch(id);
                                }}
                                text={t('savedSearch.delete.confirm.text', { searchTerm })}
                            >
                                <Tooltip title={t('delete')}>
                                    <IconButton sx={{ color: 'inherit' }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </ConfirmationDialog>
                        </>
                    ),
                    savedSearchQuery,
                    subtitle:
                        renderFiltersAsString(savedSearchQuery, t, lang) ||
                        t('savedSearch.noFilter'),
                    title: searchTerm,
                }) as QuickSearchItem,
        );
    const filteredRecentSearches = recentSearches
        ?.filter((recentSearch) => filterSearches(recentSearch, searchQuery))
        .map(
            ({ searchTerm }) =>
                ({
                    Actions: (
                        <Tooltip title={t('delete')}>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteRecentSearch(searchTerm);
                                }}
                                sx={{ color: 'inherit' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    ),
                    title: searchTerm,
                }) as QuickSearchItem,
        );

    return (
        <>
            {filteredRecentSearches?.length > 0 && (
                <MenuItems
                    Icon={<AccessTimeIcon />}
                    colorVersion={colorVersion}
                    items={filteredRecentSearches}
                    title={t('recentSearch.title')}
                />
            )}
            {filteredSavedSearches && filteredSavedSearches?.length > 0 && (
                <MenuItems
                    Icon={<BookmarkOutlineIcon />}
                    colorVersion={colorVersion}
                    items={filteredSavedSearches}
                    title={t('savedSearch.title')}
                />
            )}
        </>
    );
};
export default SuggestedSearches;
