import uniqBy from 'lodash/uniqBy';

const PREVIOUS_SEARCHES_ITEM = 'searchBox.previousSearches';

export interface RecentSearch {
    searchTerm: string;
    url: string;
}

interface UseRecentSearchMethods {
    addItem: (searchTerm: string) => void;
    deleteItem: (previousSearches: RecentSearch[], query: string) => void;
    getItems: () => RecentSearch[];
    setItem: (previousSearches: RecentSearch[], query: string) => void;
}

const useRecentSearchMethods = (): UseRecentSearchMethods => {
    const getItems = (): RecentSearch[] =>
        JSON.parse(localStorage.getItem(PREVIOUS_SEARCHES_ITEM) || 'false') || [];

    const addItem = (searchTerm: string) => {
        const previousItems = getItems();
        localStorage.setItem(
            PREVIOUS_SEARCHES_ITEM,
            JSON.stringify(
                uniqBy([{ searchTerm, url: window.location.href }, ...previousItems], 'searchTerm'),
            ),
        );
    };

    const setItem = (previousSearches: RecentSearch[], searchTerm: string) => {
        localStorage.setItem(
            PREVIOUS_SEARCHES_ITEM,
            JSON.stringify(
                uniqBy(
                    [{ searchTerm, url: window.location.href }, ...previousSearches],
                    'searchTerm',
                ),
            ),
        );
    };

    const deleteItem = (previousSearches: RecentSearch[], searchTermToDelete: string) => {
        localStorage.setItem(
            PREVIOUS_SEARCHES_ITEM,
            JSON.stringify(
                previousSearches.filter(({ searchTerm }) => searchTerm !== searchTermToDelete),
            ),
        );
    };

    return { addItem, deleteItem, getItems, setItem };
};

export default useRecentSearchMethods;
