import { Card, PaletteMode } from '@mui/material';
import { FC } from 'react';
import Suggestions from './Suggestions';

const cardSx = (colorVersion: PaletteMode) => ({
    backgroundColor: colorVersion === 'dark' ? 'secondary.main' : 'background.default',
    border: 2,
    borderColor: `grey.${colorVersion === 'dark' ? 200 : 500}`,
    pb: 2,
    position: 'absolute',
    pt: 0,
    px: 2,
    top: 68,
    width: '100%',
    zIndex: 1000,
});

interface UnderbarCardProps {
    searchQuery: string;
    colorVersion?: PaletteMode;
}

const UnderbarCard: FC<UnderbarCardProps> = ({ searchQuery, colorVersion = 'light' }) => (
    <Card sx={cardSx(colorVersion)}>
        <Suggestions colorVersion={colorVersion} searchQuery={searchQuery} />
    </Card>
);
export default UnderbarCard;
